<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />
    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />
    <!-- <dashboard-core-message-menu :expand-on-hover.sync="expandOnHover" /> -->

         <dashboard-core-view  />

     <v-overlay :value="overlay" :opacity="0">
      <v-progress-circular
        indeterminate
        width="5"
        size="65"
        color="primary"
      ></v-progress-circular>
    </v-overlay>

  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DashboardIndex",
  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreView: () => import("./components/core/View"),
    // DashboardCoreMessageMenu: () => import("./components/core/messageMenu"),
  },
  data: () => ({
    expandOnHover: false,
  }),
   computed: {
    ...mapGetters({
      overlay: "isLoading",
    }),
  },
  created() {},
};
</script>
